import styled from 'styled-components'

export const SimpleUnderline = styled('span')`
  border-bottom: ${props => props.width || '4px'} solid ${props => props.color || '#4A4A4A'};
`

const Underline = styled('span')`
  background-image: linear-gradient(${ props => props.color || 'transparent'}, ${ props => props.color || 'transparent'});
  background-position: 0 1.2em;
  background-repeat: repeat-x;
  background-size: 100% ${props => props.width || '4px'};
`

export default Underline
