import { Link } from 'gatsby'
import styled from 'styled-components'

const DescriptionLink = styled(Link)`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  font-size: 22px;
  letter-spacing: 0.01em;
  color: #4A4A4A;
  text-decoration: none;
  max-width: 241px;
  @media (min-width: 833px) {
    line-height: 48px;
    font-size: 32px;
    position: relative;
  }
`

export default DescriptionLink
