import React from 'react'
import styled from 'styled-components'

import CloseImg from 'img/close.svg';
import SignatureImg from 'img/rahim-rajan.svg';
import BillAndMelindaGatesFoundationImg from 'img/bill-and-melinda-gates-foundation.svg';

const Container = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(74, 74, 74, 0.8);
  z-index: 20;
  overflow: auto;
  overscroll-behavior: contain;
`
const Body = styled('div')`
  position: relative;
  width: 90%;
  max-width: 60.75rem;
  box-sizing: border-box;
  padding: 1rem 1.25rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 2.75rem auto;
  @media (min-width: 833px) {
    padding: 2.75rem 3.25rem;
  }
`
const Close = styled('div')`
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  width: 1rem;
  height: 1rem;
  background-image: url('${CloseImg}');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  @media (min-width: 833px) {
    right: 1rem;
    top: 1rem;
    width: 2.25rem;
    height: 2.25rem;
  }
`
const Title = styled('div')`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 42px;
  font-size: 35px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 1rem;
  @media (min-width: 833px) {
    line-height: 72px;
    font-size: 64px;
  }
`
const Subtitle = styled('div')`
  font-family: Roboto Mono, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 20px;
  color: #00A4A6;
  margin-bottom: 1.25rem;
  @media (min-width: 833px) {
    line-height: 36px;
    font-size: 24px;
  }
`
const Message = styled('div')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  font-size: 16px;
  color: #4A4A4A;
  margin-bottom: 2.5rem;
`
const Bottom = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
const SignatureContainer = styled('div')`
`
const Signature = styled('div')`
  width: 12.5rem;
  height: 3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${SignatureImg}');
  margin-bottom: 1rem;
`
const SignatureName = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: #4A4A4A;
`
const SignatureTitle = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  color: #4A4A4A;
`
const BillAndMelindaGatesFoundation = styled('div')`
  width: 4rem;
  height: 4rem;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${BillAndMelindaGatesFoundationImg}');
`
export default class MessageModal extends React.Component {
  state = {
    hideModal: false,
    messageSeen: true,
  }
  closeModal = (e) => {
    if (this.props.closeModal) return this.props.closeModal(e);
    this.setState({ hideModal: true });
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  setMessageSeen = () => {
    document.cookie = "messageSeen=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/;";
  }
  clearMessageSeen = (e) => {
    this.setState({ messageSeen: false });
    document.cookie = "messageSeen=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  componentDidMount() {
    /*
    if (window.location.search==='?resetMessage=true') {
      this.clearMessageSeen();
      this.setState({ hideModal: false, messageSeen: false });
    } else {
      const messageSeen = typeof document !== 'undefined' && document.cookie.split(';').filter((item) => item.includes('messageSeen=true')).length
      if (!messageSeen) this.setState({ messageSeen: false });
      this.setMessageSeen();
    }
    */
  }
  render() {
    if ([null, void 0].includes(this.props.showModal)) {
      if (this.state.messageSeen === true) return null;
      if (this.state.hideModal === true) return null;
    }
    if (this.props.showModal === false) return null;
    return (
      <Container onClick={this.closeModal}>
        <Body onClick={this.stopPropagation}>
          <Close onClick={this.closeModal} />
          <Title>Accelerating Education</Title>
          <Subtitle>A letter from the foundation</Subtitle>
          <Message>
            In 2014, the Bill & Melinda Gates Foundation asked innovators in digital learning to dramatically improve the quality and affordability of digital courseware so that students could achieve significantly outcomes in critical gateway undergraduate courses.  We aspired for the courseware to embody feedback and design input from instructors and students while also pushing innovative approaches to leverage emerging applications and innovations like artificial intelligence, simulations, and world class user design. While we are proud of the efforts grantees applied against such a difficult challenge, for too many students in this country, this challenge persists.  We hope that the learning derived from these grants and investments inspires educators and innovators to continue to focus on bringing current innovations in digital learning to the pedagogical challenges that prevent more low-income and students of color from achieving academic success. This is the problem set that innovation, ingenuity, and hard work must solve. We hope that the lessons and knowledge captured here renew your focus on closing these pernicious equity gaps by inspiring you to find new ways to scale student success.
          </Message>
          <Bottom>
            <SignatureContainer>
              <Signature />
              <SignatureName>
                Rahim Rajan
              </SignatureName>
              <SignatureTitle>
                Senior Program Officer,<br />
                Postsecondary Success
              </SignatureTitle>
            </SignatureContainer>
            <BillAndMelindaGatesFoundation />
          </Bottom>
        </Body>
      </Container>
    )
  }
}
