import styled from 'styled-components'

const UnorderedList = styled('ul')`
  padding-left: 20px;
  margin: 24px -20px 46px -20px;
  @media (min-width: 833px) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 28px;
  }
  li {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 17px;
    color: #4A4A4A;
    margin-bottom: 30px;
    @media (min-width: 833px) {
      line-height: 32px;
      font-size: 20px;
    }
  }
`
export const UnorderedListItem = styled('li')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  font-size: 17px;
  color: #4A4A4A;
  margin-bottom: 30px;
  @media (min-width: 833px) {
    line-height: 32px;
    font-size: 20px;
  }
`
export default UnorderedList
