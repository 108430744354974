export const autoscroll = (e) => {
  const href = e && e.currentTarget.getAttribute('href')
  const [ path, id ] = (href || '').split('#')
  const pathname = window && window.location.pathname
  if (path !== pathname) return
  e && e.preventDefault()
  const el = document.getElementById(id)
  if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  window.history.replaceState('', '', href);
}

export const scrollToHref = ({ e, href }) => {
  e && e.preventDefault()
  const id = href.replace('#', '')
  const el = document.getElementById(id)
  if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  window.history.replaceState('', '', href);
}
