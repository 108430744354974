import styled from 'styled-components'

import ArrowCoralImg from 'img/arrow-coral.svg'

const ArrowCoral = styled('div')`
  height: 30px;
  width: 70px;
  background-color: ${props => props.color || '#FF5C6B'};
  mask-image: url('${ArrowCoralImg}');
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
  margin: ${props => props.margin || '0'};
`

export default ArrowCoral
