import styled from 'styled-components'

const BodyCopy = styled('p')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  font-size: 17px;
  color: #4A4A4A;
  @media (min-width: 833px) {
    line-height: 32px;
    font-size: 20px;
  }
`

export default BodyCopy
