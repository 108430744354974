import React from 'react'
import unified from 'unified'
import markdown from 'remark-parse'
import remark2rehype from 'remark-rehype'
import rehype2react from 'rehype-react'
import baseComponents from 'components/baseComponents'

const processor = unified()
  .use(markdown)
  .use(remark2rehype)
  .use(rehype2react, {
    createElement: React.createElement,
    components: baseComponents
  })

const convertMarkdownBase = (text) => {
  if (typeof text !== 'string') return text
  return processor.processSync(text).contents
}

const convertMarkdown = (text) => {
  if (Array.isArray(text)) return text.map((t) => convertMarkdownBase(t))
  return convertMarkdownBase(text)
}

export default convertMarkdown
