import styled from 'styled-components'

import { headerTop } from 'components/header'

import CloseImg from 'img/X.svg'
import CloseImgTablet from 'img/bigX.svg'

const Close = styled('div')`
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  bottom: -40px;
  right: 1.25em;
  mask: url('${CloseImg}') no-repeat 16px;
  mask-position: center center;
  background-color: ${props => props.outlineColor || '#C62340'};
  cursor: pointer;
  display: block;
  z-index: 3;
  @media (min-width: 833px) {
    bottom: -62px;
    right: 1.5em;
    width: 62px;
    height: 62px;
    mask: url('${CloseImgTablet}') no-repeat 26px;
    mask-position: center center;
  }
`

export default Close
