import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import MessageModal from 'components/message-modal'
import { SITE_TITLE, SITE_DESCRIPTION } from 'components/site-title'

import { autoscroll } from 'components'

const Footer = styled('div')`
  padding: 36px 26px;
  background: #002955;
  z-index: 4;
  position: relative;
  flex-direction: column;
  color: #FFF7EE;
  flex: 1 0 auto;
  box-sizing: border-box;
  @media (min-width: 833px) {
    display: flex;
    padding: 50px 27px;
    flex-direction: row;
    height: 396px;
  }
`
const LeftFooter = styled('div')`
  flex: 1 0 50%;
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 60px;
  @media (min-width: 833px) {
    margin-bottom: 0;
  }
`
const FooterTitle = styled('div')`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  @media (min-width: 833px) {
    margin-bottom: 24px;
    line-height: 36px;
    font-size: 24px;
    letter-spacing: 0.01em;
  }
`
const FooterDescription = styled('div')`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`
const RightFooter = styled('div')`
  flex: 1 0 50%;
`
const FooterContainer = styled('div')`
  max-width: 409px;
`
const FooterLink = styled(Link)`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  display: block;
  color: #FFF7EE;
  font-size: 13px;
  line-height: 29px;
  @media (min-width: 833px) {
    line-height: 50px;
    font-size: 20px;
    letter-spacing: 0.01em;
  }
`
const FooterCaption = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  font-size: 12px;
  color: #FFF7EE;
  margin-top: 12px;
  a {
    color: #FFF7EE;
  }
`

export default class MainFooter extends React.Component {
  render() {
    return (
      <Footer>
        <LeftFooter>
          <FooterContainer>
            <FooterTitle>{SITE_TITLE}</FooterTitle>
            <FooterDescription>{SITE_DESCRIPTION}</FooterDescription>
            <FooterCaption>Supported by <a href="https://www.gatesfoundation.org/" target="_blank" rel="noopener">the Bill & Melinda Gates Foundation</a></FooterCaption>
          </FooterContainer>
        </LeftFooter>
        <RightFooter>
          <FooterContainer>
            <FooterLink to="/#market" onClick={autoscroll}>Market</FooterLink>
            <FooterLink to="/#product" onClick={autoscroll}>Product</FooterLink>
            <FooterLink to="/#customer" onClick={autoscroll}>Customer</FooterLink>
            <FooterLink to="/#funders" onClick={autoscroll}>Advice for funders</FooterLink>
            <FooterLink to="/about">About</FooterLink>
          </FooterContainer>
        </RightFooter>
      </Footer>
    )
  }
}
