import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Layout from 'components/layout'
import Header, { headerMargin } from 'components/header'
import Seperator from 'components/seperator'
import Underline, { SimpleUnderline } from 'components/underline'
import Footer from 'components/footer'
import ImgContainer from 'components/img-container'
import ImgBlock from 'components/img-block'
import DescriptionLink from 'components/description-link'
import ArrowDown from 'components/arrow-down-lg'
import Image from 'components/image'
import ArrowRight from 'components/arrow-right-lg'
import Video from 'components/video'

import Macaroni1Img from 'img/macaroni-1.svg'
import Macaroni2Img from 'img/macaroni-2.svg'
import DesktopImg from 'img/desktop.svg'
import ImpersonalImg from 'img/icon-impersonal.svg'
import ExpensiveImg from 'img/icon-expensive.svg'
import UnappealingImg from 'img/icon-unappealing.svg'
import AcrobatiqImg from 'img/logo-acrobatiq.png'
import LumenImg from 'img/logo-lumen.png'
import CeregoImg from 'img/logo-cerego.png'
import OLIImg from 'img/logo-OLI.png'
import CogbooksImg from 'img/logo-cogbooks.png'
import SmartSparrowImg from 'img/logo-smart_sparrow.png'
import OpenStaxImg from 'img/logo-openstax.png'
import GoodCoursewareImg from 'img/good_courseware.svg'
import GoodCoursewareImgTablet from 'img/img-good_courseware.svg'
import GoodCoursewareImgDesktop from 'img/img-good_courseware_desktop.svg'
import AdaptiveImg from 'img/icon-adaptive.svg'
import LowCostImg from 'img/icon-low_cost.svg'
import InteractiveImg from 'img/icon-interactive.svg'
import AvailableCoursewareTabletImg from 'img/img-available_courseware.svg'
import AvailableCoursewareDesktopImg from 'img/img-available_courseware_desktop.svg'

const SeeLessonsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

const BodyContent = styled('div')`
  padding: ${props => props.padding || '0 40px'};
  background: ${ props => props.background || '#FFFFFF' };
  @media (min-width: 833px) {
    padding-left: 222px;
    padding-right: 90px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const ArrowBody = styled(BodyContent)`
  padding: 40px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${SeeLessonsContainer} {
    display: none;
  }
  @media (min-width: 833px) {
    padding-left: 222px;
    padding-right: 90px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
    ${SeeLessonsContainer} {
      display: flex;
    }
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const TitleBody = styled(BodyContent)`
  padding: 133px 40px 0 40px;
  background: #FFFFFF;
  @media (min-width: 833px) {
    padding-left: 222px;
    padding-right: 90px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const InspiredBody = styled(BodyContent)`
  padding: 40px;
  background: #FFE200;
  @media (min-width: 833px) {
    padding: 133px 90px 190px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const ImmersedBody = styled(BodyContent)`
  padding: 40px;
  background: #002955;
  @media (min-width: 833px) {
    padding: 133px 90px 110px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const AvailableBody = styled(BodyContent)`
  padding: 40px 40px 0 40px;
  background: #D6D6D6;
  @media (min-width: 833px) {
    padding: 105px 90px 0 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const CurrentBody = styled(BodyContent)`
  padding: 40px;
  background: #FFFFFF;
  @media (min-width: 833px) {
    padding: 50px 90px 100px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const HypothesisBody = styled(InspiredBody)`
  padding: 40px;
  background: #FFE200;
  @media (min-width: 833px) {
    padding: 133px 90px 80px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const EnergizedBody = styled(CurrentBody)`
  padding: 40px;
  background: #FFFFFF;
  @media (min-width: 833px) {
    padding: 73px 90px 20px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const BuildBody = styled(BodyContent)`
  padding: 40px;
  background: #3D87FF;
  @media (min-width: 833px) {
    padding: 105px 90px 60px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const QualitiesBody = styled(BodyContent)`
  padding: 40px 40px 8px 40px;
  background: #FFFFFF;
  @media (min-width: 833px) {
    padding: 50px 90px 24px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const ResultsBody = styled(BodyContent)`
  padding: 120px 40px;
  background: #002955;
  @media (min-width: 833px) {
    padding-left: 222px;
    padding-right: 90px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const MotivatedBody = styled(BodyContent)`
  padding: 76px 40px;
  background: #FFE200;
  @media (min-width: 833px) {
    padding: 132px 90px 100px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const ShiftBody = styled(BodyContent)`
  padding: 81px 40px;
  background: #7FB6FF;
  @media (min-width: 833px) {
    padding: 150px 90px 100px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const SpeechBody = styled(BodyContent)`
  padding: 81px 40px;
  background: #002955;
  @media (min-width: 833px) {
    padding-left: 222px;
    padding-right: 90px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const QuestionsBody = styled(BodyContent)`
  padding: 64px 40px;
  background: #FFFFFF;
  @media (min-width: 833px) {
    padding: 165px 90px 146px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`
const EmailBody = styled(BodyContent)`
  padding: 40px;
  background: #FFF7EE;
  @media (min-width: 833px) {
    padding: 105px 90px 125px 222px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`

const Title = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  font-size: 32px;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  margin-bottom: 8px;
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    line-height: 72px;
    font-size: 64px;
    letter-spacing: 0.01em;
    color: #4A4A4A;
  }
`

const Subtitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 26px;
  letter-spacing: 0.01em;
  color: #4A4A4A;
  margin-bottom: 20px;
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    font-size: 52px;
    letter-spacing: 0.01em;
    color: #4A4A4A;
    margin-bottom: 78px;
  }
`

const Intro = styled('div')`
  border: 1px solid #002955;
  padding: 20px 25px 25px 25px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  font-size: 18px;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  background: #FFFFFF;
  position: relative;
  z-index: 2;
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 53px;
    font-size: 32px;
    letter-spacing: 0.01em;
    color: #4A4A4A;
    padding: 42px 36px 53px 39px;
    margin-right: -50px;
  }
  @media (min-width: 1024px) {
    padding: 43px 59px 31px 63px;
  }
`

const ArrowsContainer = styled('div')`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 833px) {
    flex-direction: row;
  }
`

const ReadLink = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  font-size: 22px;
  letter-spacing: 0.01em;
  color: #4A4A4A;
  text-decoration: none;
  max-width: 241px;
  margin-bottom: 24px;
  @media (min-width: 833px) {
    line-height: 48px;
    font-size: 32px;
    position: relative;
    margin-right: 24px;
  }
`

const InspiredTitle = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${props => props.color || '#4A4A4A'};
  margin-bottom: 16px;
  @media (min-width: 833px) {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    font-size: 18px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
`
const MotivatedTitle = styled(InspiredTitle)`
  color: #002955;
`

const BodyCopy = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: ${props => props.color || '#4A4A4A'};
  margin-bottom: ${ props => props.marginBottom || '42px'};
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 53px;
    font-size: 32px;
    letter-spacing: 0.01em;
  }
`
const QualitiesBodyCopy = styled(BodyCopy)`
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    font-size: 24px;
    letter-spacing: 0.01em;
  }
`

const PercentageTableTitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  font-size: 18px;
  color: #4A4A4A;
  margin-bottom: 20px;
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    font-size: 24px;
    letter-spacing: -0.01em;
    color: #4A4A4A;
  }
`

const PercentageTable = styled('div')`
`
const PercentBarContainer = styled('div')`
  background: #C7B100;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  margin: 22px 0 0 0;
`
const PercentageBarFill = styled('div')`
  position: absolute;
  left: 0;
  width: ${props => props.width || '0%'};
  height: 100%;
  background: #4A4A4A;
`
const PercentageBarText = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  font-size: 18px;
  color: #FFFFFF;
  padding: 0 10px;
  z-index: 2;
  position: relative;
`
const PercentageBarPercent = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  font-size: 18px;
  text-align: right;
  color: #4A4A4A;
  padding: 0 10px;
  z-index: 2;
  position: relative;
`
const PercentageBar = ({ text, percent }) => (
  <PercentBarContainer>
    <PercentageBarFill width={ percent } />
    <PercentageBarText>
      { text }
    </PercentageBarText>
    <PercentageBarPercent>
      { percent }
    </PercentageBarPercent>
  </PercentBarContainer>
)

const ResearchRevealed = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 17px;
  color: #4A4A4A;
  @media (min-width: 833px) {
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    font-size: 24px;
    color: #4A4A4A;
  }
`

const PercentageTableQuote = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  font-size: 12px;
  text-align: right;
  color: #000000;
  margin: 16px 0;
`

const QuoteContainer = styled('div')`
  margin: 30px 0;
  color: ${props => props.color || '#FFF7EE'};
`
const QuoteMark = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 26px;
  letter-spacing: 0.01em;
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    font-size: 100px;
    letter-spacing: 0.01em;
    margin-bottom: -50px;
  }
`
const QuoteBody = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 26px;
  letter-spacing: 0.01em;
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 62px;
    font-size: 46px;
    letter-spacing: 0.01em;
  }
`
const QuoteAttribution = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  font-size: 12px;
  margin-top: 24px;
  @media (min-width: 833px) {
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    font-size: 24px;
  }
`
const AboutQuote = ({ children, attribution, color }) => (
  <QuoteContainer color={color}>
    <QuoteBody>
      <QuoteMark>&ldquo;</QuoteMark>
      { children }
      <QuoteMark>&rdquo;</QuoteMark>
    </QuoteBody>
    { attribution ? <QuoteAttribution>{ attribution }</QuoteAttribution> : null }
  </QuoteContainer>
)
const QuoteHighlight = styled('span')`
  color: #3D87FF;
`
const CopyHighlight = styled('span')`
  color: #FFFFFF;
`

const StoryBody = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: #4A4A4A;
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    font-size: 30px;
    letter-spacing: 0.01em;
  }
`

const ComputerTable = styled('div')`
  background: #FFF7EE;
  height: 16px;
`

const AboutTable = styled('div')`
  @media (min-width: 833px) {
    display: flex;
    justify-content: space-between;

  }
`
const AboutTableItem = styled('div')`
  margin: 48px 0;
  @media (min-width: 833px) {
    flex: 0 0 29%;
  }
`
const AboutTableImg = styled('div')`
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #C4C4C4;
  display: block;
  background-image: url('${props => props.image || ''}');
  margin: 8px auto;
`
const AboutTableTitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  font-size: 18px;
  text-align: center;
  color: #4A4A4A;
  margin-bottom: 8px;
`
const AboutTableDescription = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 18px;
  color: #4A4A4A;
`

const DescriptionCopy = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 17px;
  color: #4A4A4A;
  @media (min-width: 833px) {
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    font-size: 24px;
  }
`
const ShiftCopy = styled(DescriptionCopy)`
  color: #002955;
  margin-bottom: 48px;
`

const Icons = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 40px 0;
`

const Icon = styled('div')`
  flex-grow: ${props => props.flexGrow || '0'};
  flex-basis: 45%;
  background-image: ${props => props.image ? `url('${props.image}')` : 'none'};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: ${props => props.height || '36px'};
  margin: 16px 0;
  @media (min-width: 833px) {
    flex-basis: ${props => props.flexBasis || '30%'};
    flex-grow: 0;
    order: ${props => props.order || 0};
  }
`

const MomentumItems = styled('div')`
  @media (min-width: 833px) {
    display: flex;
    justify-content: space-between;
  }
`
const MomentumItemContainer = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: #4A4A4A;
  margin-bottom: 60px;
  @media (min-width: 833px) {
    flex: 0 0 29%;
  }
`
const MomentumBar = styled('div')`
  width: 193px;
  height: 4px;
  background: ${props => props.color || 'transparent'};
  margin-bottom: 16px;
  @media (min-width: 833px) {
    width: 113px;
  }
`
const MomentumItem = ({ children, color, width }) => (
  <MomentumItemContainer>
    <MomentumBar color={color} width={width} />
    { children }
  </MomentumItemContainer>
)

const QuestionItems = styled(MomentumItems)`
`
const QuestionItemContainer = styled(MomentumItemContainer)`
`
const QuestionBar = styled(MomentumBar)`
  width: 75px;
  @media (min-width: 833px) {
    width: 65px;
  }
`
const QuestionItem = ({ children, color, width }) => (
  <QuestionItemContainer>
    <QuestionBar color={color} width={width} />
    { children }
  </QuestionItemContainer>
)

const ThoughtsCopy = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 26px;
  letter-spacing: 0.01em;
  color: #4A4A4A;
  margin-bottom: 50px;
  @media (min-width: 833px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    font-size: 52px;
    letter-spacing: 0.01em;
    color: #4A4A4A;
  }
`

const EmailCopy = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  font-size: 17px;
  color: #4A4A4A;
  margin-bottom: 40px;
  @media (min-width: 833px) {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 32px;
    font-size: 20px;
  }
`
const EmailInput = styled('input')`
  height: 52px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #002955;
  box-sizing: border-box;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
  line-height: 52px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #4A4A4A;
  margin-bottom: 16px;
`
const EmailButton = styled('button')`
  cursor: pointer;
  width: 100%;
  height: 52px;
  background: ${props => props.background || '#3D87FF'};
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 20px;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SideNav = styled('div')`
  position: sticky;
  top: 250px;
  top: calc(50vh - 158px);
  left: 40px;
  width: 128px;
  float: left;
  z-index: 3;
  display: none;
  margin-top: 368px;
  @media (min-width: 833px) {
    display: block;
    width: 160px;
  }
  @media (min-width: 1024px) {
    width: 227px;
  }
`
const NavLink = styled(AnchorLink)`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 18px;
  letter-spacing: 0.03em;
  transition: color 0.1s;
  text-decoration: none;
  color: inherit;
`
const NavItemContainer = styled('div')`
  display: inline-block;
  border-bottom: 2px ${props => props.active ? 'solid' : 'hidden'} #000000;
  transition: 0.2s border-color;
  padding: 8px 0;
  margin-bottom: 36px;
`
const NavItem = ({ id, to, children, active }) => (
  <NavItemContainer id={id} active={active}>
    <NavLink href={to}>
      { children }
    </NavLink>
  </NavItemContainer>
)

const AboutContainer = styled('div')`
`
const AboutBody = styled('div')`
  ${headerMargin}
`

const Macaroni1Container = styled(ImgContainer)`
  position: relative;
`
const Macaroni1 = styled(ImgBlock)`
  top: -112px;
  width: 133px;
  height: 224px;
  background-image: url('${Macaroni1Img}');
  @media (min-width: 833px) {
    left: 0;
    top: 0;
    width: 232px;
    height: 232px;
    background-size: 348px 696px;
    background-position: bottom right;
  }
`
const Macaroni2 = styled(ImgBlock)`
  top: -112px;
  right: -1px;
  width: 75px;
  height: 224px;
  background-image: url('${Macaroni2Img}');
  @media (min-width: 833px) {
    width: 289px;
    height: 696px;
    top: -500px;
    right: 0;
    background-size: 348px 696px;
    background-position: center left;
  }
`
const GoodCourseware = styled(ImgBlock)`
  width: 100%;
  height: 263px;
  position: relative;
  background-image: url('${GoodCoursewareImg}');
  background-position: center bottom;
  @media (min-width: 833px) {
    height: 385px;
    background-image: url('${GoodCoursewareImgTablet}');
  }
  @media (min-width: 1024px) {
    height: 385px;
    background-image: url('${GoodCoursewareImgDesktop}');
  }
`
const AvailableCourseware = styled(ImgBlock)`
  width: 100%;
  height: 233px;
  position: relative;
  background-image: url('${DesktopImg}');
  background-position: center bottom;
  @media (min-width: 833px) {
    height: 411px;
    background-image: url('${AvailableCoursewareTabletImg}');
  }
  @media (min-width: 1024px) {
    height: 411px;
    background-image: url('${AvailableCoursewareDesktopImg}');
  }
`
const AboutVideo = styled('div')`
  margin-bottom: -7px;
  @media (min-width: 833px) {
    padding-left: 222px;
    padding-right: 90px;
    margin-bottom: 93px;
  }
  @media (min-width: 1024px) {
    padding-left: 380px;
    padding-right: 153px;
  }
  @media (min-width: 1440px) {
    padding-right: calc(100vw - 1287px);
  }
`

const getTextColorsPerId = () => {
  const textColorsPerId = Array.from(document.getElementById('body').children).reduce((collector, child) => {
    const id = child.id
    if (!id) return collector
    const rgb = window.getComputedStyle(child).getPropertyValue('background-color')
    const match = rgb.match(/(\d+), (\d+), (\d+)/) || []
    const [ matchText, r = 255, g = 255, b = 255] = match
    const yiq = ((r*299) + (g*587) + (b*114))/1000
    const backgroundIsBright = yiq > 128
    const textColor = backgroundIsBright ? '#000000' : '#FFFFFF'
    return {
      ...collector,
      [id]: textColor,
    }
  }, {})
  return textColorsPerId
}

// https://codeburst.io/throttling-and-debouncing-in-javascript-646d076d0a44
function throttle(fn, delay) {
  let lastCall = 0;
  return function (...args) {
    const now = (new Date).getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  }
}

class About extends Component {
  state = {
    saving: false,
    email: '',
    textColorsPerId: {},
    saved: false,
  }
  constructor(props) {
    super(props)
    this.throttledScroll = throttle(this.onScroll, 100)
  }
  componentDidMount() {
    typeof window !== undefined && window.addEventListener('scroll', this.throttledScroll)
    const textColorsPerId = getTextColorsPerId()
    this.setState({ textColorsPerId })
    this.onScroll()
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledScroll)
  }
  checkNav = () => {
    // set the sidenav background colors
    // apparently w3c has a standard https://www.w3.org/TR/AERT/#color-contrast
    // https://trendct.org/2016/01/22/how-to-choose-a-label-color-to-contrast-with-background/
    // https://24ways.org/2010/calculating-color-contrast/
    const windowY = window.scrollY + window.innerHeight
    const navLinks = ['story1', 'story2', 'story6', 'story10']
    const navIds = ['nav1', 'nav2', 'nav3', 'nav4']
    const colorsArray = Object.entries(this.state.textColorsPerId)
    colorsArray.forEach(([bodyId, color]) => {
      const body = document.getElementById(bodyId)
      const bodyTop = body.getBoundingClientRect().top
      const bodyOffsetTop = body.offsetTop
      navIds.forEach((navId) => {
        const nav = document.getElementById(navId)
        const navBottom = nav.getBoundingClientRect().bottom
        if (bodyTop <= navBottom) {
          nav.style.color = color
          nav.style.borderColor = color
        }
      })
      if (windowY >= bodyOffsetTop && navLinks.includes(bodyId)) {
        const navIndex = navLinks.indexOf(bodyId)
        const activeNavId = navIds[navIndex]
        Array.from(document.getElementById('sidenav').children).forEach((child) => {
          if (child.id === activeNavId) {
            child.style.borderBottomStyle = 'solid'
          } else {
            child.style.borderBottomStyle = 'hidden'
          }
        })
      }
    })
  }
  onScroll = () => {
    this.checkNav()
  }
  setEmail = (e) => {
    this.setState({ email: e.currentTarget.value })
  }
  detectEnter = (e) => {
    if (e.keyCode===13) this.saveEmail()
  }
  saveEmail = () => {
    this.setState({ saving: true })
    return fetch(`/sendEmail?email=${this.state.email}`).then(() => {
      this.setState({
        saving: false,
        email: '',
        saved: true,
      })
    })
  }
  render() {
    return (
      <Layout>
        <Header background="#3D87FF" color="#FFFFFF" />
        <AboutContainer>
          <SideNav id="sidenav">
            <NavItem id="nav1" to="#story1" active={true}>Inspired by the Problem</NavItem>
            <NavItem id="nav2" to="#story2">Immersed in the Challenge</NavItem>
            <NavItem id="nav3" to="#story6">Energized by the Opportunity</NavItem>
            <NavItem id="nav4" to="#story10">Motivated by the Momentum</NavItem>
          </SideNav>
          <AboutBody id="body">
            <ImgContainer>
              <Macaroni1 />
            </ImgContainer>
            <TitleBody id="story">
              <Title>Next Generation Courseware Challenge</Title>
              <Subtitle>An evolution of learning</Subtitle>
              <Intro>
                The Next-Generation Courseware Challenge grant was launched to spur innovation in courseware, and seven grantees were funded to develop and enhance purpose-built courseware unlike anything available in the field. Explore this site to learn from their experience.
              </Intro>
            </TitleBody>
            <ImgContainer>
              <Macaroni2 />
            </ImgContainer>
            <ArrowBody id="arrows">
              <ArrowsContainer>
                <ReadLink>
                  <SimpleUnderline color="#FF5C6B">
                    Read the<br />
                    whole story
                  </SimpleUnderline>
                </ReadLink>
                <ArrowDown color="#FF5C6B" />
              </ArrowsContainer>
              <SeeLessonsContainer>
                <DescriptionLink to='/#lessons'>
                  <Underline color="#FFE200">
                    See the<br />
                    lessons
                  </Underline>
                </DescriptionLink>
                <ArrowRight color="#FFE200" margin="0 32px" />
              </SeeLessonsContainer>
            </ArrowBody>
            <InspiredBody id="story1">
              <InspiredTitle>
                Inspired by the Problem
              </InspiredTitle>
              <BodyCopy>
                For decades, the college completion gap between students of low socioeconomic status (SES) and more prosperous peers appeared insurmountable. Efforts to retain first-time, full-time students in the lower SES cohorts were failing.
              </BodyCopy>
              <PercentageTableTitle>
                Percentage of the 2004 student cohort that attained a bachelor’s degree or higher within 8 years of high school completion
              </PercentageTableTitle>
              <PercentageTable>
                <PercentageBar text="Low SES" percent="19%" />
                <PercentageBar text="Middle SES" percent="29%" />
                <PercentageBar text="High SES" percent="60%" />
              </PercentageTable>
              <PercentageTableQuote>
                NCES, 2015
              </PercentageTableQuote>
              <ResearchRevealed>
                Research revealed that “gateway” courses (i.e., 100-level foundational courses to the major field of study) can improve student outcomes. However, gateway courses are not as effective for learners from low SES backgrounds. First-generation students, especially, face a high risk of falling behind in gateway courses.
              </ResearchRevealed>
            </InspiredBody>
            <ImmersedBody id="story2" background="#002955" padding="40px">
              <InspiredTitle color="#FFF7EE">
                Immersed in the Challenge
              </InspiredTitle>
              <BodyCopy color="#FFF7EE">
                Research also shows that the right courseware tools can help move the needle of student learning and help low SES students stay on the path to completion.
              </BodyCopy>
              <AboutQuote attribution="Daniel Greenstein, former director of Postsecondary Success at the Bill & Melinda Gates Foundation, 2014 press release">
                There is a growing body of evidence that courseware, when integrated effectively by faculty in instruction, can personalize learning at unprecedented scale, potentially enabling <QuoteHighlight>all students</QuoteHighlight>—not just those who are able to attend the most elite, expensive colleges—to <QuoteHighlight>get the best and most effective education at a reasonable price</QuoteHighlight>.
              </AboutQuote>
            </ImmersedBody>
            <AvailableBody id="story3">
              <StoryBody>
                In 2014, instructors weren’t satisfied with the courseware that was available in the learning materials market.
              </StoryBody>
            </AvailableBody>
            <ImgContainer id="image2" background="#D6D6D6" >
              <AvailableCourseware />
            </ImgContainer>
            <ComputerTable id="seperator1" />
            <CurrentBody id="story4">
              <QualitiesBodyCopy>Instructors felt that current courseware offerings were...</QualitiesBodyCopy>
              <AboutTable>
                <AboutTableItem>
                  <AboutTableImg image={ImpersonalImg} />
                  <AboutTableTitle>
                    Impersonal
                  </AboutTableTitle>
                  <AboutTableDescription>
                    Rigid one-size-fits-all materials don't meet students where they are.
                  </AboutTableDescription>
                </AboutTableItem>
                <AboutTableItem>
                  <AboutTableImg image={ExpensiveImg} />
                  <AboutTableTitle>
                    Expensive
                  </AboutTableTitle>
                  <AboutTableDescription>
                    In 2014, College Board estimated that students spent upwards of $200 per textbook.
                  </AboutTableDescription>
                </AboutTableItem>
                <AboutTableItem>
                  <AboutTableImg image={UnappealingImg} />
                  <AboutTableTitle>
                    Unappealing
                  </AboutTableTitle>
                  <AboutTableDescription>
                    Textbooks, their PDF adaptation, and MOOCs appear intimidating and passive to students.
                  </AboutTableDescription>
                </AboutTableItem>
              </AboutTable>
              <DescriptionCopy>
                And market conditions made innovation difficult for courseware providers. Growth of free OER material put cost pressures on the courseware market, the rise of the textbook rental market shifted purchasing demands, major publishers held a tough-to-penetrate dominance in the market, and MOOC activity reached its peak in 2014.
              </DescriptionCopy>
            </CurrentBody>
            <HypothesisBody id="story5">
              <BodyCopy>
                Therefore, the field needed a dramatically new approach to improve student outcomes in gateway courses. The Bill & Melinda Gates Foundation hypothesized: 
              </BodyCopy>
              <AboutQuote color="#4A4A4A">
                What if increasing the access to high-quality, affordable courseware in high-enrollment, undergraduate courses could change the trajectory of a student’s higher education experience?
              </AboutQuote>
            </HypothesisBody>
            <EnergizedBody id="story6">
              <InspiredTitle>
                Energized by the Opportunity
              </InspiredTitle>
              <DescriptionCopy>
                The Next Generation Courseware Challenge invited more than 100 promising innovators to submit ambitious proposals for digital learning experiences that encompass a full course curriculum. Seven organizations, leveraging the best of current technology-enabled learning science, were selected.
              </DescriptionCopy>
              <Icons>
                <Icon image={AcrobatiqImg} flexBasis="45%" order="1" />
                <Icon image={LumenImg} flexBasis="45%" order="2" />
                <Icon image={CeregoImg} order="3" />
                <Icon image={OLIImg} order="5" />
                <Icon image={CogbooksImg} order="6" />
                <Icon image={SmartSparrowImg} order="7" />
                <Icon image={OpenStaxImg} height="48px" flexGrow="1" order="4" />
              </Icons>
            </EnergizedBody>
            <BuildBody id="story7">
              <BodyCopy marginBottom="0" color="#FFF7EE">
                The grantees created and delivered purpose-built courseware unlike anything available.
              </BodyCopy>
            </BuildBody>
            <ImgContainer id="image4" background="#3D87FF">
              <GoodCourseware />
            </ImgContainer>
            <ComputerTable id="seperator2" />
            <QualitiesBody id="story8">
              <QualitiesBodyCopy>
                They developed offerings with these essential qualities:
              </QualitiesBodyCopy>
              <AboutTable>
                <AboutTableItem>
                  <AboutTableImg image={AdaptiveImg} />
                  <AboutTableTitle>
                    Adaptive
                  </AboutTableTitle>
                  <AboutTableDescription>
                    Underrepresented student's grades showed improvement of +0.16 standard deviation units, or movement from 50th to 56th percentile.
                  </AboutTableDescription>
                </AboutTableItem>
                <AboutTableItem>
                  <AboutTableImg image={LowCostImg} />
                  <AboutTableTitle>
                    Low Cost
                  </AboutTableTitle>
                  <AboutTableDescription>
                    Combining student and instutional expenses, cost savings ranged from $36-$231 less per student.
                  </AboutTableDescription>
                </AboutTableItem>
                <AboutTableItem>
                  <AboutTableImg image={InteractiveImg} />
                  <AboutTableTitle>
                    Interactive
                  </AboutTableTitle>
                  <AboutTableDescription>
                    Participating instructors noted better comprehension and retention, improved engagement, and better analysis and application of course content.
                  </AboutTableDescription>
                </AboutTableItem>
              </AboutTable>
              <BodyCopy>
                During the three-year period from 2015 to 2017, the courseware of the seven NGCC grantees scaled to 138,000 students, 1,000 instructors, and 449 higher-ed institutions. <SimpleUnderline width="2px" color="#3D87FF">In seven of nine studies from the grant, students and institutions achieved cost savings without compromising student learning.</SimpleUnderline>
              </BodyCopy>
              <DescriptionCopy>
                Meet the providers:
              </DescriptionCopy>
            </QualitiesBody>
            <AboutVideo>
              <Video src="https://player.vimeo.com/video/294965052" />
            </AboutVideo>
            <ResultsBody id="story9">
              <BodyCopy color="#FFF7EE">
                As a result of using the grant courseware, most instructors surveyed agreed that courseware benefitted students’ learning outcomes. <QuoteHighlight>Well-implemented, adaptive, next-gen courseware holds promise for enhancing course outcomes of underrepresented students.</QuoteHighlight>
              </BodyCopy>
            </ResultsBody>
            <MotivatedBody id="story10">
              <MotivatedTitle>
                Motivated by the Momentum
              </MotivatedTitle>
              <BodyCopy>
                The learning materials market has come a long way in the last few years. We celebrate a subset of gains made that have moved the field forward:
              </BodyCopy>
              <MomentumItems>
                <MomentumItem color="#3D87FF">
                  A new found urgency to offer cheaper solutions for students and the rise of open education resources
                </MomentumItem>
                <MomentumItem color="#FF5C6B">
                  Growing interest in the power of adaptive and mastery-based solutions
                </MomentumItem>
                <MomentumItem color="#00A4A6">
                  The emergence of true partnerships between vendors and “early adopter” institutions to build solutions that meet real needs
                </MomentumItem>
              </MomentumItems>
            </MotivatedBody>
            <ShiftBody id="story11">
              <ShiftCopy>
                 As the learning materials market continues to shift, both existing and emergent trends continue to inhibit scaling and sustainability in the implementation of new solutions.
              </ShiftCopy>
              <ShiftCopy>
                Instructors face <CopyHighlight>high switching costs</CopyHighlight> when choosing courseware over traditional textbooks, emerging inclusive access offerings are <CopyHighlight>shifting business models</CopyHighlight>, institutions’ slow and rigid <CopyHighlight>purchasing cycles inhibit adoption</CopyHighlight>, and <CopyHighlight>established publishers</CopyHighlight> continue to dominate relationships with faculty.
              </ShiftCopy>
            </ShiftBody>
            <SpeechBody id="story12">
              <AboutQuote attribution="Bill Gates, 2016 ASU-GSV speech">
                When we think about the job market, that’s changing too and so we really need to get kids to a higher level. <QuoteHighlight>New technology alone doesn’t solve this. It’s a combination of great teachers using the technology in the right way.</QuoteHighlight>
              </AboutQuote>
            </SpeechBody>
            <QuestionsBody id="story13" padding="64px 40px">
              <BodyCopy>
                With significant progress achieved and continued work ahead, several research questions remain.
              </BodyCopy>
              <QuestionItems>
                <QuestionItem color="#3D87FF" width="75px">
                  What instructional and environmental conditions and practices are necessary for courseware to enhance student outcomes?
                </QuestionItem>
                <QuestionItem color="#FF5C6B" width="75px">
                  What does a successful implementation process look like in 4-year colleges versus 2-year colleges?
                </QuestionItem>
                <QuestionItem color="#00A4A6" width="75px">
                  What impact does high-quality, affordable courseware have on longer-term degree attainment (i.e., beyond course-level completion)?
                </QuestionItem>
              </QuestionItems>
              <ThoughtsCopy>
                The Foundation has thought deeply about the insights from the NGCC in its strategy to facilitate necessary changes <Underline color="#3D87FF">that will enable institutions to implement courseware to its full potential.</Underline> Explore these articles to learn from the grantees experience.
              </ThoughtsCopy>
              <SeeLessonsContainer>
                <DescriptionLink to='/#lessons'>
                  <Underline color="#FFE200">
                    See the<br />
                    lessons
                  </Underline>
                </DescriptionLink>
                <ArrowRight color="#FFE200" margin="0 32px" />
              </SeeLessonsContainer>
            </QuestionsBody>
            <EmailBody id="email">
              <EmailCopy>
                The Every Learner, Everywhere network was formed to create smarter demand and implementation of courseware at institutions. Interested in staying updated on DLSN’s work? Submit your email here:
              </EmailCopy>
              <EmailInput onChange={this.setEmail} onKeyDown={this.detectEnter} value={this.state.email} />
              <EmailButton disabled={this.state.saving} onClick={this.saveEmail} background={(this.state.saving || this.state.saved) ? '#4A4A4A' : null}>
                { this.state.saved ? 'Got it! Thanks for your interest.' : 'Keep me updated' }
              </EmailButton>
            </EmailBody>
          </AboutBody>
        </AboutContainer>
        <Footer />
      </Layout>
    )
  }
}

export default About
