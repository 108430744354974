import styled from 'styled-components'

const OutlineHeader = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${ props => props.color || '#FF8F99'};
  margin-bottom: 8px;
  @media (min-width: 833px) {
    letter-spacing: 0.08em;
    line-height: 20px;
  }
`

export default OutlineHeader
