import React from 'react'
import styled from 'styled-components'
import convertMarkdown from 'components/convertMarkdown'

export const QuoteContainer = styled('div')`
  margin: 32px -20px 30px -20px;
  @media (min-width: 833px) {
    margin: 5em 0 5em 0;
  }
`
export const Quote = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  font-size: 24px;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  @media (min-width: 833px) {
    line-height: 48px;
    font-size: 36px;
  }
`
export const QuoteAttribution = styled('div')`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #4A4A4A;
  margin-top: -1.5em;
  @media (min-width: 833px) {
    font-size: 18px;
    line-height: 24px;
  }
`

const QuoteItem = (props) => {
  const { attribution, children } = props
  const body = convertMarkdown(children)
  const attributionBlock = attribution ? (
    <QuoteAttribution>{convertMarkdown(attribution)}</QuoteAttribution>
  ) : null
  return (
    <QuoteContainer>
      <Quote>
        { body }
      </Quote>
      { attributionBlock }
    </QuoteContainer>
  )
}

export default QuoteItem
