import styled from 'styled-components'

const ImgBlock = styled('div')`
  width: ${ props => props.width || '0'};
  height: ${ props => props.height || '0'};
  background-image: ${ props => props.src ? `url('${props.src}')` : 'none'};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: ${props => props.backgroundPosition || 'center center'};
  position: ${ props => props.position || 'absolute'};
  top: ${ props => props.top || 'auto'};
  left: ${ props => props.left || 'auto'};
  right: ${ props => props.right || 'auto'};
  bottom: ${ props => props.bottom || 'auto'};
  margin: ${ props => props.margin || 'auto'};
  z-index: 1;
`

export default ImgBlock
