import styled from 'styled-components'

const Link = styled('a')`
  color: #3D87FF;
  &:visited {
    color: #002955;
  }
`

export default Link
