import styled from 'styled-components'

const Seperator = styled('div')`
  width: ${props => props.width || '100%'};
  border-bottom: 4px solid ${props => props.color || '#00A4A6'};
`

export default Seperator

export const PageLeftSeperator = styled(Seperator)`
  width: 120px;
  border-bottom: 4px solid ${props => props.color || '#3D87FF'};
  margin: 34px 0 38px 0;
`

export const PageSeperator = styled(PageLeftSeperator)`
  margin: ${props => props.mobileAlignLeft ? '34px 0 38px 0' : '34px auto 38px auto'};
  @media (min-width: 1024px) {
    margin: 34px auto 38px auto;
  }
`
