import styled from 'styled-components'

const ArticleSubtitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 26px;
  letter-spacing: 0.01em;
  color: #4A4A4A;
  margin-bottom: 20px;
  @media (min-width: 833px) {
    line-height: 54px;
    font-size: 48px;
  }
  p {
    margin: 0;
  }
`

export default ArticleSubtitle
