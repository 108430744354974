import React from 'react'
import styled from 'styled-components'
import convertMarkdown from 'components/convertMarkdown'
import categoryToColors from 'components/categoryToColors'

import BodyCopy from 'components/body-copy'

export const NoteHeader = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${ props => props.color || '#FF8F99'};
  margin-bottom: 8px;
  @media (min-width: 833px) {
    letter-spacing: 0.05em;
    line-height: 24px;
  }
  @media (min-width: 833px) {
    letter-spacing: 0.08em;
    line-height: 20px;
  }
`

export const Note = styled('div')`
  width: 100%;
  margin: 54px auto 48px auto;
  border: 1px solid #002955;
  padding: 20px;
  box-sizing: border-box;
  @media (min-width: 833px) {
    width: auto;
    max-width: 398px;
  }
  ${props => props.pushRight ? `
  @media (min-width: 1440px) {
    width: 232px;
    position: absolute;
    right: 0;
  }
  @media (min-width: 1550px) {
    right: -187px;
  }
  ` : `
  @media (min-width: 1250px) {
    width: 232px;
    position: absolute;
    right: 0;
  }
  `};
`
const NoteBody = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 17px;
  color: #4A4A4A;
  p, ${BodyCopy} {
    margin-top: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: 17px;
    color: #4A4A4A;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const NoteItem = (props) => {
  const { title, children, category, pushRight } = props
  const { color } = (categoryToColors[category] || {})
  const body = convertMarkdown(children)
  return (
    <Note pushRight={pushRight}>
      <NoteHeader color={color}>{ title }</NoteHeader>
      <NoteBody>
        { body }
      </NoteBody>
    </Note>
  )
}

export default NoteItem
