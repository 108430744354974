import ArticleSubtitle from 'components/article-subtitle'
import OutlineTitle from 'components/outline-title'
import OutlineHeader from 'components/outline-header'
import Note from 'components/note'
import Quote from 'components/quote'
import UnorderedList from 'components/unordered-list'
import OrderedList from 'components/ordered-list'
import Link from 'components/link'

const components = {
  'h1': ArticleSubtitle,
  'h2': OutlineTitle,
  'h3': OutlineHeader,
  'note': Note,
  'quote': Quote,
  'pre': Quote,
  'ul': UnorderedList,
  'ol': OrderedList,
  'a': Link,
}

export default components
