const categoryToColors = {
  Market: {
    color: '#00A4A6',
    lightColor: '#58D6D7',
    darkColor: '#00A4A6',
  },
  Product: {
    color: '#3D87FF',
    lightColor: '#7FB6FF',
    darkColor: '#005BCB',
  },
  Customer: {
    color: '#FF5C6B',
    lightColor: '#FF8F99',
    darkColor: '#C62340',
  },
  Funders: {
    color: '#9357DE',
    lightColor: '#9357DE',
    darkColor: '#7546B1',
  },
  About: {
    color: '#002955',
    lightColor: '#002955',
    darkColor: '#002955',
  }
}

export default categoryToColors
