import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { autoscroll } from 'components'
import Close from 'components/close'
import HamburgerImg from 'img/hamburger.svg'
import CloseImg from 'img/close-hamburger.svg'
import HamburgerWhiteImg from 'img/hamburger-white.svg'
import CloseWhiteImg from 'img/close-hamburger-white.svg'
import { SITE_TITLE, SITE_DESCRIPTION } from 'components/site-title'

const Header = styled('div')`
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
`
const HeaderTop = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${ props => props.color || '#002955'};
  background: ${ props => props.background || '#FFFFFF' };
  padding: 0 9.5px;
  flex: 1 0 auto;
  box-sizing: border-box;
  text-align: center;
  min-height: 53px;
  &:before {
    position: absolute;
    content: '';
    width: ${props => props.articleOffset ? 'calc(100% - 40px)' : '100%'};
    left: ${props => props.articleOffset ? '20px' : '0'};
    bottom: -3px;
    height: 3px;
    background-image: linear-gradient(rgba(0, 41, 85, 0.1), rgba(0, 41, 85, 0));
  }
`
const Title = styled(Link)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  color: ${ props => props.color || '#002955'};
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 833px) {
    font-size: 16px;
  }
`
const Menu = styled('div')`
  display: none;
  @media (min-width: 833px) {
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    margin-left: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    position: relative;
    &:before {
      content: '';
      display: block;
      height: 40px;
      top: 7px;
      width: 1px;
      background: ${props => props.invertMenu ? '#FFFFFF' : '#000000'};
      position: absolute;
    }
  }
`
const MenuA = styled('a')`
  text-decoration: none;
  color: ${ props => props.invertMenu ? '#4a4a4a' : (props.color || '#002955')};
  font-size: 15px;
  display: flex;
  min-height: 53px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 833px) {
  color: ${ props => props.invertMenu ? '#ffffff' : (props.color || '#002955')};
    font-size: 16px;
    margin: 0 16px;
    padding: 0 10px;
  }
`
const MenuItem = styled(Link)`
  text-decoration: none;
  color: ${ props => props.invertMenu ? '#4a4a4a' : (props.color || '#002955')};
  font-size: 15px;
  display: flex;
  min-height: 53px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 833px) {
    color: ${ props => props.invertMenu ? '#ffffff' : (props.color || '#002955')};
    font-size: 16px;
    margin: 0 16px;
    padding: 0 10px;
  }
`
const MenuLeft = styled('div')`
  display: none;
  @media (min-width: 833px) {
    display: flex;
    align-items: center;
  }
`
const MenuRight = styled('div')`
  display: none;
  @media (min-width: 833px) {
    display: flex;
  }
`

const MobileHamburger = styled('div')`
  ${props => props.invertMenu ? `
  background: url('${props.menuOpen ? CloseWhiteImg : HamburgerWhiteImg}') center / auto no-repeat;
  ` : `
  background: url('${props.menuOpen ? CloseImg : HamburgerImg}') center / auto no-repeat;
  `}
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-right: 3px;
  @media (min-width: 833px) {
    display: none;
  }
`
const HeaderBottom = styled('div')`
  background: #ffffff;
  max-height: ${props => props.menuOpen ? '400px' : '0'};
  transition: max-height 0.25s ease-in-out;
  padding: 0 10px;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    width: 35px;
    height: 4px;
    background: #002955;
    margin: 4px 0;
  }
  @media (min-width: 833px) {
    display: none;
  }
`

export default class HeaderFull extends React.Component {
  state = {
    menuOpen: false,
  }
  toggleMenuOpen = () => {
    this.setState((state) => ({
      menuOpen: !state.menuOpen
    }))
  }
  closeAndAutoscroll = (e) => {
    autoscroll(e)
    this.setState({
      menuOpen: false,
    })
  }
  render() {
    const { background, articleOffset, color, showClose, navigate, invertMenu } = this.props
    const { menuOpen } = this.state
    return (
      <Header>
        <HeaderTop color={color} background={background} articleOffset={articleOffset}>
          <Title color={color} to="/">{SITE_TITLE}</Title>
          <MobileHamburger menuOpen={menuOpen} onClick={this.toggleMenuOpen} invertMenu={invertMenu} />
          { showClose ? <Close onClick={() => navigate('/')} outlineColor={background} /> : null}
          <Menu invertMenu={invertMenu}>
            <MenuLeft>
              <MenuA invertMenu={invertMenu} color={color} href='/#market' onClick={this.closeAndAutoscroll}>Market</MenuA>
              <MenuA invertMenu={invertMenu} color={color} href='/#product' onClick={this.closeAndAutoscroll}>Product</MenuA>
              <MenuA invertMenu={invertMenu} color={color} href='/#customer' onClick={this.closeAndAutoscroll}>Customer</MenuA>
              <MenuA invertMenu={invertMenu} color={color} href='/#funders' onClick={this.closeAndAutoscroll}>Advice for funders</MenuA>
            </MenuLeft>
            <MenuRight>
              <MenuItem invertMenu={invertMenu} color={color} to='/about'>About</MenuItem>
            </MenuRight>
          </Menu>
        </HeaderTop>
        <HeaderBottom menuOpen={menuOpen}>
          <MenuA invertMenu={invertMenu} color={color} href='/#market' onClick={this.closeAndAutoscroll}>Market</MenuA>
          <MenuA invertMenu={invertMenu} color={color} href='/#product' onClick={this.closeAndAutoscroll}>Product</MenuA>
          <MenuA invertMenu={invertMenu} color={color} href='/#customer' onClick={this.closeAndAutoscroll}>Customer</MenuA>
          <MenuA invertMenu={invertMenu} color={color} href='/#funders' onClick={this.closeAndAutoscroll}>Advice for funders</MenuA>
          <MenuItem invertMenu={invertMenu} color={color} to='/about'>About</MenuItem>
        </HeaderBottom>
      </Header>
    )
  }
}

export const headerMargin = `
  margin-top: 53px;
`
