import styled from 'styled-components'

const OutlineTitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
  font-size: 24px;
  letter-spacing: -0.01em;
  margin-bottom: 28px;
  color: #4A4A4A;
`

export default OutlineTitle
