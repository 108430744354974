import React from 'react'
import styled from 'styled-components'

import convertMarkdown from 'components/convertMarkdown'
import { ModalConsumer } from 'components/modal'

const ImageContainer = styled('div')`
  margin: ${props => props.margin || '45px -38px 58px -38px'};
  @media (min-width: 833px) {
    margin-left: 0;
    margin-right: 0;
  }
`
const ImageBackground = styled('div')`
  background: #C4C4C4;
`
const Image = styled('img')`
  width: 100%;
  display: block;
  background-image: ${props => props.src ? `url('${props.src}')` : 'none'};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`
export const ImageCaption = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  font-size: 12px;
  text-align: right;
  color: #4A4A4A;
  padding: 8px;
`

const ImageLightboxContainer = styled('div')`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
`

const FullSizeImage = styled('img')`
  margin: 0 auto;
  max-width: 100%;
`

const ImageLightbox = ({ src, onRequestClose }) => (
  <ImageLightboxContainer onClick={onRequestClose}>
    <FullSizeImage src={src} />
  </ImageLightboxContainer>
)

const ImageItem = ({ src, children, margin, enableLightbox }) => (
  <ImageContainer margin={margin}>
    <ModalConsumer>
      {({ showModal }) => (
        <ImageBackground>
          <Image src={ src } onClick={enableLightbox ? () => showModal(ImageLightbox, { src }) : null} />
        </ImageBackground>
      )}
    </ModalConsumer>
    { children ? <ImageCaption>{ convertMarkdown(children) }</ImageCaption> : null }
  </ImageContainer>
)

export default ImageItem
