import React from 'react'
import styled from 'styled-components'
import { ImageCaption } from 'components/image'
import convertMarkdown from 'components/convertMarkdown'

const IframeWrapper = styled('div')`
`

const IframeContainer = styled('div')`
  position: relative;
  padding-top: 56.25%;
`

const Iframe = styled('iframe')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VideoIframe = ({ id, src, children }) => (
  <IframeWrapper>
    <IframeContainer>
      <Iframe src={ src } frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></Iframe>
    </IframeContainer>
    { children ? <ImageCaption>{ convertMarkdown(children) }</ImageCaption> : null }
  </IframeWrapper>
)

export default VideoIframe
