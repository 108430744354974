import styled from 'styled-components'

import ArrowYellowImg from 'img/arrow-yellow.svg'

const ArrowYellow = styled('div')`
  height: 70px;
  width: 30px;
  background-color: ${props => props.color || '#FFE200'};
  mask-image: url('${ArrowYellowImg}');
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
`

export default ArrowYellow
